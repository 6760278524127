import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';

const Download = () => {
    const [downloadUrl, setDownloadUrl] = useState('');
    const location = useLocation();

    useEffect(() => {
        // Ensure window is defined and accessible
        if (typeof window !== 'undefined') {
            // Get the full current URL
            const currentUrl = window.location.href;
            
            // Check if the current URL already ends with '/download'
            const isDownloadPage = currentUrl.endsWith('/download');
            
            // Construct the download URL only if '/download' is not already in the URL
            const newDownloadUrl = isDownloadPage ? currentUrl : `${currentUrl}/download`;
            
            // Set the constructed URL
            // console.log(newDownloadUrl, 'current url');
            setDownloadUrl(newDownloadUrl);
        }
    }, [location.pathname]);

    // Check if current path is / or ends with /download
    const shouldShowButton = !(
        location.pathname === '/' || location.pathname.endsWith('/download') || location.pathname.endsWith('/main') || location.pathname.endsWith('/about-us') || location.pathname.endsWith('/contact')
    );

    if (!shouldShowButton) return null;

    const size = 32;

    return (
        <motion.button
            className='bg-black text-white px-6 py-2 h-26 w-26 border-1 border-white flex items-center justify-center'
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
        >
            <Link to={downloadUrl} className='flex space-x-2 text-2xl font-light justify-center items-end'>
                Download
                <svg 
                    width={size} 
                    height={size} 
                    viewBox="0 0 64 64" 
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none" 
                    strokeWidth="2" 
                    strokeLinecap="round" 
                    strokeLinejoin="round"
                >
                    <line x1="32" y1="12" x2="32" y2="44" stroke="#FFFFFF" />
                    <polyline points="20,32 32,44 44,32" stroke="#FFFFFF" />
                    <line x1="12" y1="44" x2="12" y2="52" stroke="#FFFFFF" />
                    <line x1="52" y1="44" x2="52" y2="52" stroke="#FFFFFF" />
                    <line x1="12" y1="52" x2="52" y2="52" stroke="#FFFFFF" />
                </svg>
            </Link>
        </motion.button>
    );
};

export default Download;
