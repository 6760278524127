import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import Loading from '../components/Loading';
import Error from '../components/Error';
import ProductList from '../components/ProductList';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useState } from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
const GET_CATEGORIES = gql`
  query GetAllCategories ($title: String!) {
    productCategories(filters: { title: { eq: $title }}) {
      data {
        id
        attributes {
          title
          coverImageURL
        }
      }
    }
  }
`;




export default function Category() {

  const navigate = useNavigate();

  const location = useLocation();
  const categoryId = location.state ? location.state.cat : null;

  const { title} = useParams();

const [mountProducts, setMountProducts] = useState(false);
  const { loading: loadingCategories, error: errorCategories, data: dataCategories } = useQuery(GET_CATEGORIES, {
    variables: { title },
  });

// console.log("cate gory ddata", dataCategories);

  if (loadingCategories) {
    return <Loading />;
  }

  if (errorCategories) {
    

    return <Error />
  }
 


  const productCategoryData = dataCategories.productCategories?.data[0];
const products = productCategoryData?.attributes?.products?.data || [];


  // const matchedCategory = dataCategories.productCategories.data.find(
  //   (category) => 
  //     category.attributes.title.toLowerCase().replace(/\s+/g, '') === 
  //     categoryName.toLowerCase().replace(/\s+/g, '')
  // );
  
const mountProductList = () => {
  setMountProducts(true);
}
  return (
    <motion.div                   
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0, delay: 0.5 }} // Adding a delay of 0.5 seconds before exit animation
    transition={{ duration: 2 }}
     className='overflow-y-auto w-full'>
      <div>
        <Link to='/main' className='underline font-bold text-[9px] p-8'>
          Our Products
        </Link>
      </div>
      <div className='w-full flex flex-col'>
        <h1 className='italic uppercase md:text-6xl text-2xl font-light px-8 py-4'>{title}</h1>

          <LazyLoadImage onLoad={mountProductList} effect='blur' className="w-full h-[50vh] object-cover" src={productCategoryData.attributes.coverImageURL} alt="Category Main" />

      </div>
      {mountProducts ? <ProductList mount={mountProducts} categoryId={productCategoryData.id} categoryName={title} /> : <Loading />
      }
      
    </motion.div>

  );
}
