import React, { useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';
import { useRef } from 'react';
import emailjs from '@emailjs/browser';
const GET_PRODUCTS = gql`
query {
  products( pagination: { limit: 200 }) {
    data {
      attributes {
        productID
      }
    }
  }
}
`
export default function ContactUs({onClose}) {

  const { loading, error, data } = useQuery(GET_PRODUCTS);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();



    emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID_2, form.current, process.env.REACT_APP_EMAILJS_PUBLIC_KEY)
      .then((result) => {
        setFormSubmitted(true);
          resetForm();
      }, (error) => {
          console.log(error.text);
      });
  };

const resetForm = () => {
  form.current.reset();
};

if (loading) return <p>Loading...</p>;
if (error) return <p>Error: {error.message}</p>;

  const products = data?.products?.data || []; // Safely access the products array

// console.log(products, 'products');
  return (
    <div className=" ">
      <div className='text-[9px] p-6 underline font-bold'>
        <nav><Link to='/main'className='uppercase'>Our Products</Link></nav>
      </div>
      <div className='md:pt-40'>
        <h2 className="text-[50px] font-light mb-4 md:w-[75%] p-8">Get In Touch with Aqualona</h2>

        {/* contact form  */}

        <div className='md:grid grid-cols-3 md:p-8 p-2 max-md:pb-4  relative'>
        <div className={`w-full h-full absolute top-0 bg-brand flex flex-col ${formSubmitted ? 'block' : 'hidden'}`}>
          <div className='text-center m-auto space-y-8'> 
            <svg className='m-auto' width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clipRule="evenodd" d="M13.4881 0.40225C13.8026 0.67182 13.839 1.14529 13.5695 1.45979L4.99803 11.4598C4.85554 11.626 4.64753 11.7217 4.42858 11.7217C4.20964 11.7217 4.00163 11.626 3.85914 11.4598L0.430571 7.45978C0.161001 7.14528 0.197431 6.67178 0.511921 6.40228C0.826411 6.13268 1.29989 6.16908 1.56946 6.48358L4.42858 9.81928L12.4306 0.4836C12.7001 0.16911 13.1736 0.13268 13.4881 0.40225Z" fill="black"/>
            </svg>

            <p className='uppercase m-auto'>Form Submitted</p>
          </div>
          </div>
          <h3 className='uppercase inline text-[12px] pt-2'>Send us a form</h3>
          <form ref={form} onSubmit={sendEmail} className='col-span-2 uppercase text-[9px]'>
            
            <div className="mb-4 inline-flex space-x-2 w-full ">
              <input
              placeholder='First Name'
                type="text"
                id="name"
                name="firstName"
                className="w-full border-b border-black bg-transparent px-3 py-2"
              />

              <input
              placeholder='Last Name'
                type="text"
                id="name"
                className="w-full border-b border-black bg-transparent px-3 py-2"
                name="lastName"
              />
            </div>


            <div className="mb-4 inline-flex space-x-2 w-full">
              <input
                placeholder='Email Address'
                type="email"
                id="email"
                className="w-full border-b border-black bg-transparent px-3 py-2"
                name="email"
              />
              <input
                placeholder='Phone Number'
                id="number"
                className="w-full border-b border-black bg-transparent px-3 py-2"
                rows="4"
                name="number"
              />
            </div>
            <div className='mb-4 '>
              <select id="category" name="productID" className='bg-transparent border-b border-black px-3 py-2 w-full' placeholder='Product No.'>
                <option value="">Product No.</option>
                {products.map((product) => (
                  <option key={product.attributes.productID} value={product.attributes.productID}>
                    {product.attributes.productID}
                  </option>
                ))}
              </select>

            </div>

            <div className=" pb-20">

              <button
                type="submit"
                className="px-4 py-2 bg-black hover:bg-blue-600 text-white uppercase text-[10px] font-bold float-right"
              >
                Confirm
              </button>
            </div>
          </form>
          <div className='absolute bottom-0 mx-auto  translate-y-[50%] p-4 text-[9px] w-full'>
            <p className='text-center w-[20px] bg-[#E6E4E0] mx-auto'>or</p>
            </div>
        </div>

        {/* contact details */}
        <div className='border-t border-black md:grid grid-cols-3 w-full p-8'>
          <h3 className='uppercase text-[12px] w-1/2 md:text-center pt-2'>Contact At</h3>
          <div className='col-span-2 text-[9px]'>
            <address className='not-italic'>
              Telephone: <a href="tel:01707641929" className='font-bold'>0170 764 1929</a>
            </address>
            <address className='not-italic'>
              Email: <a href="mailto:sales@aqualona.com" className='font-bold'>sales@aqualona.com</a>
            </address>
          </div>
        </div>
      </div>
    </div>
  )
}
