import React from 'react'
import { Link } from 'react-router-dom';
import mid from '../assets/mid.png'
import { gql, useQuery } from '@apollo/client';
import Loading from '../components/Loading';

const GET_ABOUT_US_DATA = gql`
  query {
    aboutUs {
      data {
        attributes {
          pageHeader
          paragraph1
          paragraph2
          slogan
          image1URL
          image2URL
          landscapeImageURL
        }
      }
    }
  }
`;

export default function AboutUs() {

  const {loading, error, data } = useQuery(GET_ABOUT_US_DATA);

  if(loading){
    return <Loading />
  }
  if(error) {
    return <p>Error loading data.</p>
  }

  const {pageHeader, paragraph1, paragraph2, slogan, image1URL, image2URL, landscapeImageURL } = data.aboutUs.data.attributes;
  return (
      <div className='w-full h-full overflow-y-auto'>
        <nav className='py-2 px-6 '>
          <Link to='/main' className='uppercase underline text-[8px] font-semibold'>Our Products</Link>
        </nav>
        <section className='space-y-4 p-6 md:p-8'>
          <h1 className='text-4xl font-extralight py-4'>{pageHeader}</h1>
          <div className='w-full flex max-md:flex-col md:space-x-8'>
            <div className='w-full md:w-1/2 space-y-2 text-sm md:text-xs font-light max-md:order-2 max-md:pt-4'>
              {paragraph1.split('\n').map((para, index) => (
                <p className='text-justify' key={index}>{para}</p>
              ))}
              {/* <img src={mid} className='pt-4' alt='' /> */}
            </div>
            <div className='space-y-2 w-full md:w-1/2'>
              <img src={image1URL} className='w-full' alt='' />
              <img src={image2URL} className='w-full' alt='' />

            </div>
          </div>
          </section>
          <section className='bg-black px-6 md:px-8 py-8 md:py-10 text-white space-y-6 max-md:pb-20'>
            <div className='w-full md:flex md:space-x-8 max-md:space-y-4'>
            <p className='font-bold text-xl md:w-1/2'>{slogan}</p>
              <div className='md:w-1/2 space-y-2'>
                {paragraph2.split('\n').map((para, index) => (
                  <p className='text-justify text-xs font-extralight' key={index}>{para}</p>
                ))}
              </div>

                
              </div>
              <img src={landscapeImageURL} alt='' className='w-full' />

          </section>
      </div>
  )
}
