import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../assets/logo.png'
import { motion } from 'framer-motion';
import { GET_HOME_DATA } from '../GraphQL';
import { useQuery } from '@apollo/client';
import Loading from '../components/Loading';

export default function HomePage() {
    const { loading, error, data } = useQuery(GET_HOME_DATA);

    if(loading) {
       return  <Loading />
    }
    if(error) {
       return <p>Found error while loading data. Please refresh the page.</p>
    }

    const {pageHeader, subHeader, button, description, coverImageURL} = data.home.data.attributes;


  return (
    <motion.div         initial={{ x: window.innerWidth }}
    animate={{ x: 0 }}
    exit={{ opacity: 0 }} className='grid-flow-row grid-rows-2 w-full h-full p-2'>
        {/* background image */}
        <div className='w-[100%]! h-[85%] relative' style={{backgroundImage: `url(${coverImageURL})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
            <div className='relative h-full '>
                {/* AQUALONA LOGO */}
                <p className='pt-12 text-white text-[14px] mx-auto text-center pb-2'>Powered by</p>
                <img src={Logo} alt='nothing' className='w-[187px] mx-auto' />
                <div className='absolute bottom-10 lg:bottom-24 right-10 w-[55%] text-right text-white'>
                    <h1 className='uppercase  text-[28px]  lg:text-[40px] '>{pageHeader}</h1>
                    <p className='text-[18px] font-light'>{subHeader}</p>
                </div>
            </div>
        </div>
        <div className='h-[15%]'>
            <Link className='flex transition-colors duration-500 hover:bg-gray-300 h-full' to='/main' >
                <div className='font-plus-jakarta-sans mx-auto py-8 space-y-2 group'>
                    <h2 className='uppercase inline-flex font-bold tracking-widest mx-auto text-[14px] text-center w-full'> 
                    <span className='mx-auto pr-0 mr-0 '>{button}</span>
                        <svg className='my-auto mx-auto group-hover:animate-bounce-right' width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M0 5.00002C0 4.86741 0.0526784 4.74024 0.146447 4.64647C0.240215 4.5527 0.367392 4.50002 0.5 4.50002H12.293L9.146 1.35402C9.05211 1.26013 8.99937 1.1328 8.99937 1.00002C8.99937 0.867245 9.05211 0.739908 9.146 0.646021C9.23989 0.552134 9.36722 0.49939 9.5 0.49939C9.63278 0.49939 9.76011 0.552134 9.854 0.646021L13.854 4.64602C13.9006 4.69247 13.9375 4.74764 13.9627 4.80839C13.9879 4.86913 14.0009 4.93425 14.0009 5.00002C14.0009 5.06579 13.9879 5.13091 13.9627 5.19165C13.9375 5.2524 13.9006 5.30758 13.854 5.35402L9.854 9.35402C9.76011 9.44791 9.63278 9.50065 9.5 9.50065C9.36722 9.50065 9.23989 9.44791 9.146 9.35402C9.05211 9.26013 8.99937 9.1328 8.99937 9.00002C8.99937 8.86725 9.05211 8.73991 9.146 8.64602L12.293 5.50002H0.5C0.367392 5.50002 0.240215 5.44734 0.146447 5.35357C0.0526784 5.25981 0 5.13263 0 5.00002Z" fill="black"/>
                        </svg>
                    </h2>
                    <p className='text-center text-[10px]'>{description}</p>
                </div>
            </Link>
        </div>
        
    </motion.div>
  )
}
