import React from 'react'
import { gql, useQuery } from '@apollo/client';
import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import { Font } from '@react-pdf/renderer'


const GET_ALL_PRODUCTS = gql`
  query GetProducts {
    products ( pagination: { limit: 200 }) {
      data {
        attributes {
          productID
          title
          price
          product_category {
            data {
              id
            }
          }
        }
      }
    }
  }
`;


Font.register({
    family: 'Plus Jakarta Sans',
    fonts: [
      { src: 'https://fonts.gstatic.com/s/plusjakartasans/v8/LDIbaomQNQcsA88c7O9yZ4KMCoOg4IA6-91aHEjcWuA_KU7NShXUEKi4Rw.ttf', fontWeight: '200' },
      { src: 'https://fonts.gstatic.com/s/plusjakartasans/v8/LDIbaomQNQcsA88c7O9yZ4KMCoOg4IA6-91aHEjcWuA_907NShXUEKi4Rw.ttf', fontWeight: '300' },
      { src: 'https://fonts.gstatic.com/s/plusjakartasans/v8/LDIbaomQNQcsA88c7O9yZ4KMCoOg4IA6-91aHEjcWuA_qU7NShXUEKi4Rw.ttf', fontWeight: 'normal' },
      { src: 'https://fonts.gstatic.com/s/plusjakartasans/v8/LDIbaomQNQcsA88c7O9yZ4KMCoOg4IA6-91aHEjcWuA_m07NShXUEKi4Rw.ttf', fontWeight: '500' },
      { src: 'https://fonts.gstatic.com/s/plusjakartasans/v8/LDIbaomQNQcsA88c7O9yZ4KMCoOg4IA6-91aHEjcWuA_d0nNShXUEKi4Rw.ttf', fontWeight: '600' },
      { src: 'https://fonts.gstatic.com/s/plusjakartasans/v8/LDIbaomQNQcsA88c7O9yZ4KMCoOg4IA6-91aHEjcWuA_TknNShXUEKi4Rw.ttf', fontWeight: '700' },
      { src: 'https://fonts.gstatic.com/s/plusjakartasans/v8/LDIbaomQNQcsA88c7O9yZ4KMCoOg4IA6-91aHEjcWuA_KUnNShXUEKi4Rw.ttf', fontWeight: '800' },
    ],
  });





const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#E4E4E4',
    paddingHorizontal: 20,
    paddingTop: 40,
    paddingBottom: 60,
    fontFamily: 'Plus Jakarta Sans',

  },
  header: {
    fontSize: 50,
    fontWeight: 'light',
    textAlign: 'left',
    marginBottom: 40,
    
  },
  sectionHeader: {
    fontSize: 12,
    fontWeight: '500',
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    marginBottom: 10,
    textTransform: 'uppercase',
    
  },
  productList: {
    fontSize: 12,
    flexDirection: 'column',
    fontWeight: 'normal'
  },
  productRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#C5BEB1',
    paddingVertical: 15,
  },
  productCell: {
    flex: 1,
  },
  productCellWide: {
    flex: 3,
  },
  footer: {
    position: 'absolute',
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: 'center',
    fontSize: 12,
  },
});


export default function TradePriceListPDF() {
    const { loading, error, data } = useQuery(GET_ALL_PRODUCTS);

    const sortedProducts = data && [...data.products.data].sort((a, b) => {
      const categoryA = a.attributes.product_category.data?.id || '';
      const categoryB = b.attributes.product_category.data?.id || '';
      return categoryA.localeCompare(categoryB);
    });
  
    if (loading) {
      return <div>Loading...</div>;
    }
  
    if (error) {
      return <div>Error: {error.message}</div>;
    }

    return(
    <PDFViewer style={{ width: '100%', height: '100%' }}>
    <Document title='Aqualona E-Brochure Trade Prices'>
    <Page size="A4" style={styles.page} wrap>
      <Text style={styles.header}>Trade Price List</Text>
      <View style={styles.sectionHeader}>
        <Text style={styles.productCell}>Product No.</Text>
        <Text style={styles.productCellWide}>Product Name</Text>
        <Text style={styles.productCell}>Price</Text>
      </View>
      <View style={styles.productList}>
        {sortedProducts.map((product) => (
          <View key={product.attributes.productID} style={styles.productRow} id={product.attributes.productID}>
            <Text style={styles.productCell}>{product.attributes.productID}</Text>
            <Text style={styles.productCellWide}>{product.attributes.title}</Text>
            <Text style={styles.productCell}>
              {product.attributes.price && product.attributes.price !== 0 ? `£${product.attributes.price}` : ''}
            </Text>
          </View>
        ))}
      </View>
      <Text style={styles.footer} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} fixed />
    </Page>
  </Document>
  </PDFViewer>
  )
}
