import React from 'react'
import loading from '../assets/loading.gif'
export default function Loading() {
  return (
    <div className='w-full h-full flex'>
      <div className='m-auto w-[40px] h-[40px]'>
            <img className='m-auto w-full h-full object-contain' src={loading} alt='animation of loading cirlce' />
        </div>
    </div>
  )
}
