import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import Loading from '../components/Loading';

const GET_CATEGORY_PAGE_DATA = gql`
  query {
    category {
      data {
        attributes {
          pageHeader
          paragraph
          coverImageURL
        }
      }
    }
  } 
`;
const GET_CATEGORIES = gql`
  query GetAllCategories {
    productCategories(sort: "order") {
      data {
        id
        attributes {
          title
          iconURL
          order 
        }
      }
    }
  }
`;

export default function ProductSummary() {
  const { loading, error, data } = useQuery(GET_CATEGORIES);
  const { loading: loadingPage, error: errorPage, data: pageData } = useQuery(GET_CATEGORY_PAGE_DATA);
  if (loading || loadingPage) {
    return <Loading />;
  }

  if (error || errorPage) {
    return <div>Error loading categories: {error.message}</div>;
  }

  const categories = data?.productCategories?.data;

  const { pageHeader, paragraph, coverImageURL } = pageData?.category?.data?.attributes || {};

    // Sort the categories based on the 'order' field
    // categories.sort((a, b) => a.attributes.order - b.attributes.order);


  return (
    <div className="h-[100%] w-full">
      <div className='w-full h-[15%] md:h-[25%] px-12 py-4 md:py-20 text-white relative' style={{backgroundImage: `url(${coverImageURL})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
        <div className='h-full top-0 relative space-y-4'>
          <h1 className='md:text-4xl font-thin uppercase text-center'>
            {pageHeader}
          </h1>
          <p className='text-center font-extralight lg:px-40 text-[10px]'>{paragraph}</p>
        </div>
      </div>
      <div className="h-[75%] md:h-[65%] border-black flex w-full">
        <ul className="grid grid-cols-2 md:grid-cols-3 grid-rows-5 md:grid-rows-3 h-auto w-full">
          {categories &&
            categories.map((category) => (
              <Link
              to={`/category/${category.attributes.title}`}
              className="border-[0.5px] border-[#C0C0C0] transition duration-1000 flex hover:bg-[#DCD9D3]"
            >
              <li
                key={category.id}
                className=" block m-auto text-center text-[10px] uppercase "
              >

                  <img
                    src={category.attributes.iconURL}
                    className="mx-auto w-[40px] h-[40px]"
                    alt={category.attributes.title}
                  />
                  <p className='max-md:pt-2'>{category.attributes.title}</p>

              </li>
              </Link>
            ))}
            <li className='md:hidden border-[0.5px] border-[#C0C0C0]'></li>
        </ul>
      </div>
      
      <div className="inline-flex w-full uppercase font-bold h-[10%]">
      <Link to="/about-us" className='border-[1px] border-black border-b-0 border-l-0 w-1/2 p-8 transition duration-1000 flex hover:bg-blue-200'>
        <div className="">
          About Us
        </div>
        </Link>
        <Link to="/contact" className="border-[1px] border-black border-x-0 border-b-0 w-1/2 p-8 transition duration-1000 flex hover:bg-blue-200">
        <div >
          Contact Us
        </div>
        </Link>
      </div>
    </div>
  );
}
