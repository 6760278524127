
import { useQuery, gql } from '@apollo/client';
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from './Loading';

const GET_PRODUCT_BY_ID = gql`
query GetProductTitleByProductID($productId: String!) {
  products(filters: { productID: { eq: $productId } }) {
    data {
      attributes {
        title
      }
    }
  }
}



`;

const getCurrentDateTime = () => {
    const currentDate = new Date();
  
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    };
  
    const formattedDate = currentDate.toLocaleDateString('en-US', options);
  
    const day = currentDate.getDate();
    const suffix = (() => {
      if (day >= 11 && day <= 13) {
        return 'th';
      }
      switch (day % 10) {
        case 1:
          return 'st';
        case 2:
          return 'nd';
        case 3:
          return 'rd';
        default:
          return 'th';
      }
    })();
  
    return `${formattedDate} ${day}${suffix}`;
  };
  

  

export default function ContactForm() {
  const navigate = useNavigate();

  const location = useLocation();
  const pathArray = location.pathname.split('/'); // Split the path by '/'
  const productID = decodeURIComponent(pathArray[pathArray.length - 2]);
  console.log(productID, ":productID");
  
  const form = useRef();
  const [formSubmitted, setFormSubmitted] = useState(false);

  const { loading, error, data } = useQuery(GET_PRODUCT_BY_ID, {
    variables: { 
      productId: productID,
     },
  });


  if(loading){
    return <Loading />
  }

  if(error) {
    return <p>error loading data</p>
  }
    const sendEmail = (e) => {
      e.preventDefault();


      
        // formData.append('submission_date', getCurrentDateTime());
  
      emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, form.current, process.env.REACT_APP_EMAILJS_PUBLIC_KEY)
        .then((result) => {
          setFormSubmitted(true);
            resetForm();
        }, (error) => {
            console.log(error.text);
        });
    };

    const resetForm = () => {
        form.current.reset();
      };

      const close = () => {
        navigate('..');
      }
      console.log(data, ':contact form data')
  return (
    <div className='bg-black bg-opacity-60 w-full h-full flex absolute top-0 z-50'>
      
        <div className='md:w-2/3 max-md:w-full max-md:h-full md:max-h-2/3 border-[0.5px] border-black bg-brand m-auto p-4 md:p-20 space-y-4 relative'>
        <div className='absolute top-0 right-0 p-4'><button onClick={close}>&#x2715;</button></div>
            <h1 className={`text-2xl uppercase ${formSubmitted ? 'hidden' : ''}`}>Get in Touch</h1>
            <form ref={form} onSubmit={sendEmail} className='grid grid-cols-2 grid-rows-6 gap-6 text-xs relative'>
              {/* when for has been sent  */}
            <div className={`w-full h-full absolute top-0 bg-brand flex flex-col ${formSubmitted ? 'block' : 'hidden'}`}>
            <div className='text-center m-auto space-y-8'> 
            <svg className='m-auto' width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clipRule="evenodd" d="M13.4881 0.40225C13.8026 0.67182 13.839 1.14529 13.5695 1.45979L4.99803 11.4598C4.85554 11.626 4.64753 11.7217 4.42858 11.7217C4.20964 11.7217 4.00163 11.626 3.85914 11.4598L0.430571 7.45978C0.161001 7.14528 0.197431 6.67178 0.511921 6.40228C0.826411 6.13268 1.29989 6.16908 1.56946 6.48358L4.42858 9.81928L12.4306 0.4836C12.7001 0.16911 13.1736 0.13268 13.4881 0.40225Z" fill="black"/>
            </svg>

            <p className='uppercase m-auto'>Form Submitted</p>
          </div>
          
        </div>
      <div className=' flex flex-col'>
        <label htmlFor="firstName">First Name:</label>
        <input
        className='bg-transparent border-b-[0.5px] border-black focus:outline-black'
          type="text"
          id="firstName"
          name="firstName"
          required
        />
      </div>
      <div className=' flex flex-col'>
        <label className='' htmlFor="lastName">Last Name:</label>
        <input
        className='bg-transparent border-b-[0.5px] border-black focus:outline-black'
          type="text"
          id="lastName"
          name="lastName"
          required
        />
      </div>
      <div className='col-span-2 flex flex-col'>
        <label htmlFor="email">Email:</label>
        <input
        className='bg-transparent border-b-[0.5px] border-black focus:outline-black'
          type="email"
          id="email"
          name="email"
          required
        />
      </div>
      <div className='col-span-2 row-start-3 flex flex-col'>
        <label htmlFor="phoneNumber">Phone Number:</label>
        <input
        className='bg-transparent border-b-[0.5px] border-black focus:outline-black'
          type="tel"
          id="phoneNumber"
          name="phoneNumber"
          required
        />
      </div>
      <div className='col-span-2 row-start-4 flex flex-col'>
        <label htmlFor="productName">Product Name:</label>
        <input
        className='bg-transparent border-b-[0.5px] border-black focus:outline-black'
          type="text"
          id="productName"
          name="productName"
          value={data.products.data[0].attributes.title}
          disabled
        />
      </div>
      <div className='col-span-2 row-start-5 flex flex-col'>
        <label htmlFor="productNumber">Product ID:</label>
        <input
        className='bg-transparent border-b-[0.5px] border-black focus:outline-black'
          type="text"
          id="productId"
          name="productId"
          value={productID}
          disabled
        />
      </div>
      <button className='col-start-2 row-start-6 bg-black text-white uppercase' type="submit">Submit</button>
    </form>


        </div>
    </div>
  )
}
