import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { Outlet } from 'react-router-dom';
import QRCode from 'react-qr-code';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { motion } from 'framer-motion';

const GET_PRODUCT_BY_ID = gql`
  query GetProduct($id: ID!) {
    product(id: $id) {
      data {
        attributes {
          productID
          title
          description
          product_category {
            data {
              attributes {
                title
              }
            }
          }
          product_images {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export default function Product() {
  const [currentUrl, setCurrentUrl] = useState('');
  const location = useLocation();
  const pathname = location.pathname.split('/');
  const productId = pathname[3];

  useEffect(() => {
    setCurrentUrl(window.location.href);

    const handleUrlChange = () => {
      setCurrentUrl(window.location.href);
    };

    window.addEventListener('popstate', handleUrlChange);

    return () => {
      window.removeEventListener('popstate', handleUrlChange);
    };
  }, []);

  const { loading, error, data } = useQuery(GET_PRODUCT_BY_ID, {
    variables: { id: productId },
  });
console.log(productId, "product id")
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const { product } = data;
  const { attributes } = product.data;
  const { productID, title, description, product_images, product_category } = attributes;
  const imageList = product_images && product_images.data ? product_images.data : [];
  const categoryName = product_category && product_category.data ? product_category.data.attributes.title : null;

  return (
<motion.div 
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0, delay: 0.5 }} // Adding a delay of 0.5 seconds before exit animation
                  transition={{ duration: 2 }}
                 className='w-full h-full overflow-y-auto'>

<Outlet />  


      <div className='flex w-full'>
        {/* navigation path */}
        <nav className='text-[8px] md:w-[80%] w-1/2 md:p-8 p-2'>
          <ul className='uppercase inline-flex space-x-1'>
            <li className='underline'><Link to='/main'>Our Products</Link></li>
            <li>/</li>
          <li className='underline'><Link to={`/category/${categoryName}`} state={{cat: product_category.data.id}} >{categoryName}</Link></li>
          </ul>
        </nav>


      
      </div>
      <div className='md:p-20 p-2 md:pt-0 flex flex-col '>
        <div className='space-y-4'>
          <div>
          <h1 className='md:text-4xl text-3xl font-extralight uppercase'>{title}</h1>
          <div className='flex justify-between'>
          <p className='max-md:font-light text-[10px]'> {productID}</p>
              <Link to='/priceList'className='font-bold text-[8px] inline-flex my-auto group ' state={{productID: productID}}>
                View Trade Price <span className='group-hover:animate-bounce-right'>&rarr;</span>
              </Link>
          </div>
          </div>
        
        <div className='space-y-6 w-full flex flex-col'>
    
        {imageList.map((product_image, index) => (
          <div key={index} className='w-full h-full overflow-hidden bg-white p-2 flex'>
            <div className='m-auto hover:scale-[200%] transform transition duration-500'>
                <LazyLoadImage
                  key={product_image.id}
                  src={product_image.attributes.url}
                  alt={` ${product_image.id}`}
                  className='w-full object-contain max-h-80 '
                  effect='blur'
              />
          </div>
          </div>
        ))}
        </div>
        <div className='md:inline-flex md:space-x-8 py-4'>
          <div className='space-y-4'>
            {/* Conditionally render the description */}
            <div className='text-xs w-full space-y-4'>
            {description.split('\n').map((paragraph, index) => (
              <p key={index} className=''>{paragraph}</p>
            ))}
        </div>
    {/* <span className='text-black block text-xs font-light md:text-[8px]'>£{price}</span> */}
    <Link to='/priceList' className='font-bold text-[8px] inline-flex group' state={{ productID: productID }}>
      View Trade Price <span className='group-hover:animate-bounce-right'>&rarr;</span>
    </Link>
  </div>
</div>

          

        </div>
        

                {/* qr code */}
        <div className='bg-gradient-to-r from-black via-black to-gray-700 w-full h-full flex px-4 py-4 float-right text-white text-sm justify-between'>
          <div className='md:w-2/3 justify-between h-[80px] md:h-[100px] flex flex-col'>
            <div>
              <Link to='enquiry' state={productID} className=''>
              <p className='font-semibold'>
                Want to learn more about this product?
              </p>
              <p className='font-light text-xs md:hidden'>Enquire here &rarr;</p>
              </Link>
              <p className='font-light text-xs max-md:hidden'>Scan the QR Code</p>
              
            </div>
            <div className='text-xs'>
              <Link to='https://www.aqualona.com/'>Contact us at aqualona.com</Link>
            </div>
          </div>
          <div className='bg-white h-auto w-auto p-2 flex max-md:hidden h-[60px] w-[100px]'>
            <QRCode className='w-full m-auto h-20' value={`${currentUrl}/enquiry`} />

          </div>
      </div>
      </div>
    

     

    </motion.div>


  );
}
