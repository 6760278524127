import React, { useEffect, useState } from 'react';
import { Page, Text, View, Document, StyleSheet, Font, Image, PDFViewer, Link } from '@react-pdf/renderer';
import { useQuery, gql } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import QRCode from 'react-qr-code';

// GraphQL query
const GET_PRODUCT_BY_ID = gql`
  query GetProduct($id: ID!) {
    product(id: $id) {
      data {
        attributes {
          productID
          title
          description
          product_category {
            data {
              attributes {
                title
              }
            }
          }
          product_images {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

// Register fonts
Font.register({
  family: 'Plus Jakarta Sans',
  fonts: [
    { src: 'https://fonts.gstatic.com/s/plusjakartasans/v8/LDIbaomQNQcsA88c7O9yZ4KMCoOg4IA6-91aHEjcWuA_KU7NShXUEKi4Rw.ttf', fontWeight: '200' },
    { src: 'https://fonts.gstatic.com/s/plusjakartasans/v8/LDIbaomQNQcsA88c7O9yZ4KMCoOg4IA6-91aHEjcWuA_907NShXUEKi4Rw.ttf', fontWeight: '300' },
    { src: 'https://fonts.gstatic.com/s/plusjakartasans/v8/LDIbaomQNQcsA88c7O9yZ4KMCoOg4IA6-91aHEjcWuA_qU7NShXUEKi4Rw.ttf', fontWeight: 'normal' },
    { src: 'https://fonts.gstatic.com/s/plusjakartasans/v8/LDIbaomQNQcsA88c7O9yZ4KMCoOg4IA6-91aHEjcWuA_m07NShXUEKi4Rw.ttf', fontWeight: '500' },
    { src: 'https://fonts.gstatic.com/s/plusjakartasans/v8/LDIbaomQNQcsA88c7O9yZ4KMCoOg4IA6-91aHEjcWuA_d0nNShXUEKi4Rw.ttf', fontWeight: '600' },
    { src: 'https://fonts.gstatic.com/s/plusjakartasans/v8/LDIbaomQNQcsA88c7O9yZ4KMCoOg4IA6-91aHEjcWuA_TknNShXUEKi4Rw.ttf', fontWeight: '700' },
    { src: 'https://fonts.gstatic.com/s/plusjakartasans/v8/LDIbaomQNQcsA88c7O9yZ4KMCoOg4IA6-91aHEjcWuA_KUnNShXUEKi4Rw.ttf', fontWeight: '800' },
  ],
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#E4E4E4',
    flexDirection: 'column',
    paddingHorizontal: 80,
    paddingTop: 20,
    paddingBottom: 20,
    fontFamily: 'Plus Jakarta Sans',
    position: 'relative',
    pageBreakInside: 'avoid',
  },
  section: {
    marginBottom: 10,
  },
  header: {
    fontSize: 10,
    textTransform: 'uppercase',
    fontWeight: '200',
    marginBottom: 20,
    position: 'absolute',
    top: 0,
  },
  title: {
    fontSize: 36,
    fontWeight: '200',
    textTransform: 'uppercase',
    marginBottom: 10,
  },
  text: {
    fontSize: 12,
    marginBottom: 10,
  },
  image: {
    width: '100%',
    maxHeight: '100%',
    paddingBottom: 20,
  },
  qrCode: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
    padding: 10,
    backgroundColor: '#000',
    color: '#fff',
    pageBreakInside: 'avoid',
  },
  qrCodeText: {
    fontSize: 12,
    width: '40%'
  },
  qrCodeImage: {
    width: '50%',
    height: 60,
    color: 'white',
    textDecorationColor: 'white',
    textDecorationStyle: 'none'
  },
  spinner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#E4E4E4',
  },
  link: {
    fontSize: 18,
    color: 'white',
    textDecoration: 'underline',
    fontWeight: '600',
    textAlign: 'right'
  },


});

const ProductPDF = () => {
  const location = useLocation();
  const pathname = location.pathname.split('/');
  const productId = pathname[3];
  const [base64Images, setBase64Images] = useState([]);
  const [imageFetchError, setImageFetchError] = useState(false);
  const [loadingPDF, setLoadingPDF] = useState(true);

  const { loading, error, data } = useQuery(GET_PRODUCT_BY_ID, {
    variables: { id: productId },
  });

  useEffect(() => {
    const fetchImages = async () => {
      if (data) {
        const imageUrls = data.product.data.attributes.product_images.data.map(
          img => img.attributes.url
        );

        const imagePromises = imageUrls.map(async (url) => {
          try {
            const response = await fetch(url);
            if (!response.ok) throw new Error('Network response was not ok');
            const blob = await response.blob();
            const reader = new FileReader();
            return new Promise((resolve, reject) => {
              reader.onloadend = () => resolve(reader.result);
              reader.onerror = reject;
              reader.readAsDataURL(blob);
            });
          } catch (error) {
            console.error('Failed to fetch image:', url, error);
            setImageFetchError(true);
            return null;
          }
        });

        const base64Images = await Promise.all(imagePromises);
        setBase64Images(base64Images.filter(img => img !== null));
      }
    };

    fetchImages().then(() => setLoadingPDF(false)); // Set loading to false after images are fetched
  }, [data]);

  if (loading || loadingPDF) {
    return (
      <div className='w-full h-full flex justify-center items-center'>
        <div className='w-full text-center'>Loading data...</div> {/* Replace with spinner if you have one */}
      </div>
    );
  }

  if (error) return <div>Error: {error.message}</div>;

  if (!data || !data.product || !data.product.data || !data.product.data.attributes) {
    return <div>No product data found.</div>;
  }

  const { attributes } = data.product.data;
  const { productID, title, description, product_category } = attributes;
  const categoryName = product_category && product_category.data ? product_category.data.attributes.title : null;

  return (
    <div className='w-full h-full justify-center place-items-center' style={{zIndex: 0}}>
      <p className='absolute top-0 -z-10 w-full h-full flex justify-center items-center'>Generating PDF...</p>
      <PDFViewer style={{ width: '100%', height: '100%', zIndex: 20 }}>
        <Document>
          <Page size="A4" style={styles.page} wrap>
            <View style={styles.section}>
              <Text style={styles.title}>{title}</Text>
              <Text style={styles.text}>{productID}</Text>
              <View style={styles.section}>
                {base64Images.map((base64Image, index) => (
                  <Image key={index} src={base64Image} style={styles.image} />
                ))}
              </View>
              {imageFetchError && (
                <View style={styles.section}>
                  <Text style={styles.text}>Some images failed to load.</Text>
                </View>
              )}
              <Text style={styles.text}>{description}</Text>
            </View>
            {/* <View style={styles.qrCode}>
              <Text style={styles.qrCodeText}>Want to learn more about this product?</Text>
              <View style={styles.qrCodeImage}>
                <Link  src={`${pathname}/enquiry`} style={styles.link}>Enquire here</Link>
              </View>
            </View> */}
          </Page>
        </Document>
      </PDFViewer>
    </div>
  );
};

export default ProductPDF;
