import React from 'react'
import { useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';
import Loading from './Loading';
import Error from './Error';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
const GET_PRODUCTS_FOR_CATEGORY = gql`
  query GetCats($id: ID!) {
    productCategory(id: $id ) {
      data {
        attributes {
          products(pagination: { limit: 80 }, sort: "orderBy") {
            data {
              id
              attributes {
                productID
                title
                description
                product_images {
                  data {
                    attributes{
                      url
                    }
                  }
                }
                product_category {
                  data {
                    id
                    attributes {
                     title
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export default function ProductList({categoryId, categoryName}) {
console.log(categoryId, 'catID')
    const { loading: loadingProducts, error: errorProducts, data: dataProducts } = useQuery(GET_PRODUCTS_FOR_CATEGORY, {
        variables: { id: categoryId },
      });


      if(loadingProducts){
        return <Loading />
      }

      if(errorProducts || categoryName === 'NULL') {
        return <Error />
      }

      const productCategoryData = dataProducts.productCategory?.data;
const products = productCategoryData?.attributes?.products?.data || [];

  return (
    <div>
    <ul className='w-full flex-col'>
    {products.map((product) => (
  <Link
    to={`/category/${categoryName}/${product.id}/${product.attributes.productID}`}
    key={product.id}
    state = {{ product : product}}
  >
    <li className='w-full h-[200px] grid grid-cols-2 md:grid-cols-3  md:grid-rows-2 max-md:grid-rows-auto md:gap-2 py-4 px-2 md:pl-8 md:pr-4 border-b-[0.8px] border-black transition-colors duration-500 hover:bg-[#857A5C] hover:bg-opacity-25' key={product.id}>
          <span className='md:row-span-5 text-[8px] md:text-xs max-md:h-2'>{product.attributes.productID}</span>
          <h2 className='uppercase md:col-span-2 max-md:col-start-1 max-md:row-start-2'>{product.attributes.title}</h2>
          <p className='text-[8px] md:col-start-2 md:col-span-2 md:row-start-2 md:pr-4 row-span-3 max-md:col-start-1 max-md:row-start-3 max-md:row-span-4  overflow-y-auto'>{product.attributes.description.split('\n').splice(0,1).map((paragraph, index) => (
            <p key={index} className='pb-[1px]'>{paragraph.slice(0,160)}...</p>
          ))}</p>
          <div className='row-span-3 col-start-4 row-start-1 max-md:row-span-5 max-md:col-start-2 max-md:row-start-1 w-[150px] max-md:mx-auto my-auto h-[150px]'>
            {product.attributes.product_images?.data?.length > 0 && (
              <div className='bg-white p-4 flex w-full h-full'>
                <div className='m-auto  h-full flex'>
                <LazyLoadImage
                    src={product.attributes.product_images.data[0].attributes.url}
                    alt=''
                    className='object-contain w-full h-full m-auto'
                    effect="blur"
                />
              </div>
              </div>
            )}
        </div>
    </li>
  </Link>
    ))}
    <li className='text-xs m-auto p-8 italic text-center underline'><Link to='/main'>Back to Main Menu</Link></li>
  </ul>
</div>
  )
}
