import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
const GET_ALL_PRODUCTS = gql`
  query GetProducts {
    products ( pagination: { limit: 200 }) {
      data {
        attributes {
          productID
          title
          price
          product_category {
            data {
              id
            }
          }
        }
      }
    }
  }
`;

export default function PriceList() {
  const { loading, error, data } = useQuery(GET_ALL_PRODUCTS);
  const location = useLocation();
  const scrollProductID = location.state?.productID;
  console.log(scrollProductID, "scroll to");
  const [highlightedProduct, setHighlightedProduct] = useState(null);
  useEffect(() => {
    if (scrollProductID) {
      const element = document.getElementById(scrollProductID);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        setHighlightedProduct(scrollProductID);
      }
    }
  }, [scrollProductID]);

  // sorting by categoryID
  const sortedProducts = data && [...data.products.data].sort((a, b) => {
    // Using optional chaining and providing a fallback value to ensure the sort can handle null values
    const categoryA = a.attributes.product_category.data?.id || '';
    const categoryB = b.attributes.product_category.data?.id || '';
    return categoryA.localeCompare(categoryB);
  });
  


  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  

  return (
    <div className='w-full p-2 md:p-8 overflow-y-scroll max-h-full'>
      <nav>
        <ul className='underline uppercase text-[8px] inline-flex space-x-[1.5px]'>
          <li><Link to='/main'>
            Our Products</Link>
          </li>
          <li className=''> / </li>
          <li className='underline'>
            Trade Price List
          </li>
        </ul>
      </nav>


      <h1 className='font-light text-[50px]'>Trade Price List</h1>

      <ul className=' w-full py-12 shrink-0 '>
        <li>
          <ul className='uppercase grid grid-cols-4 md:grid-cols-8 font-bold border-b border-black text-[12px]'>
            <li className=' max-md:hidden col-span-2'>Product No.</li>
            <li className='col-span-3 md:col-span-5'>Product Name</li>
            <li>Price</li>
          </ul>
        </li>
        {sortedProducts.map((product) => (
          <li key={product.attributes.productID} id={product.attributes.productID}
          className={`pt-4 pb-2 border-b border-[#C5BEB1] py-2 ${highlightedProduct === product.attributes.productID ? 'bg-gray-300' : ''}`}>
            <ul className='grid md:grid-cols-8 grid-cols-4  text-[12px] w-full h-full px-2'>
              <li className='col-span-3 md:col-span-2'>{product.attributes.productID}</li>
              <li className='col-span-3 md:col-span-5 max-md:row-start-2'>{product.attributes.title}</li>
              <li>{product.attributes.price && product.attributes.price !== 0 ? `£${product.attributes.price}` : ''}</li>

            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
}
