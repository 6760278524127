import React from 'react'
import { Link } from 'react-router-dom'

export default function Error() {
  return (
    <div className='w-full h-full flex'>
        <div className='text-center m-auto space-y-6'>
            <h1 className='font-bold'>Error loading page</h1>
            <p className='font-thin'>Please refresh the page or return to the main menu.</p>
            <Link className='py-2 underline hover:font-bold text-sm' to='/main/'>Main Menu</Link>
        </div>

    </div>
  )
}
